import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92208bc6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "privacy-policy" }
const _hoisted_2 = { class: "section-default" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "content__headline" }
const _hoisted_5 = { class: "content__headline-title" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "content__action" }
const _hoisted_8 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex",
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 24,
                md: 16,
                lg: 16,
                xl: 16
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translation.privacyPolicy), 1)
                    ]),
                    _createElementVNode("div", {
                      class: "content__description",
                      innerHTML: _ctx.translation.privacyPolicyContent
                    }, null, 8, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_router_link, { to: { name: 'HomeIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.return), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("footer", null, [
      _createVNode(_component_el_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 16,
            type: "flex",
            align: "middle"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, { to: { name: 'TermsOfUseIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.termsOfUse), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createVNode(_component_router_link, { to: { name: 'PrivacyPolicyIndexVue' } }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translation.privacyPolicy), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12,
                xl: 12
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.translation.allRightsReserved) + " © " + _toDisplayString(_ctx.getYear), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}